import request from '@/utils/request';

const PATH = '/nice';

export const getEncData = () => request({
  url: PATH,
  method: 'get',
});

export const getInfo = (encodeData: string) => request({
  url: `${PATH}/info`,
  method: 'get',
  params: {
    encData: encodeData,
  },
});
