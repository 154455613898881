
import { getInfo } from '@/api/nice';
import { checkPhone } from '@/api/user';
import { NiceModule } from '@/store/nice';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'NiceAccept',
})
export default class extends Vue {
  mounted() {
    getInfo(String(this.$route.query.EncodeData)).then((res) => {
      window.opener.document.getElementById('actualName').value = res.data.name;
      window.opener.document.getElementById('phone').value = res.data.phone;
      window.close();
    });
  }
}
